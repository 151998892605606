// This component only manages the view of the search
// Any changes to defaults for filtering/searching will also need to be added to ClassResults component

import React from "react";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import qs from "qs";
import _ from "lodash";
import { Configure } from "react-instantsearch-dom";
import { getFilterEventObj } from "helpers/filtersUtils";
import {
  contentFilterClasses,
  libraryFilterContent,
  AccountMode,
} from "services/typewriter/segment";
import { getFiltersApplied } from "helpers/getFiltersApplied";
import { LIBRARY_MODULE, ANALYTICS_LOCATION } from "constants/index";
import { FamilyContext } from "app/components/AppProviders/FamilyContextProvider";
import {
  UserAccessTypeToAlgoliaFilterMap,
  searchClient,
} from "app/components/Algolia/constants";
import { AccessType } from "services/graphql";
import { FilterModal } from "../FilterModal";
import { InstructorOptions } from "../InstructorOptions";
import { DurationOptions } from "../DurationOptions";
import { LevelOptions } from "../LevelOptions";
import { SortOptions } from "../SortOptions";
import ClassResults from "./ClassResults";
import { ExplicitMusicToggle } from "../ExplicitMusicToggle";
import { StyleOptions } from "../StyleOptions";
import { TypeOptions } from "../TypeOptions";
import { AvailableToMeToggle } from "../AvailableToMeToggle";
import { FamilyFriendlyToggle } from "../FamilyFriendlyToggle";
import { AlgoliaIndexes } from "../types";
import { FILTERS, DURATION_ITEMS, SORT_BY_ITEMS } from "./constants";
import { WithMusicOnlyToggle } from "../WithMusicOnlyToggle";

const createURL = state => {
  const { ...withoutPage } = state;
  return `?${qs.stringify(withoutPage)}`;
};
const urlToSearchState = location => qs.parse(location.search.slice(1));
const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : "";

class ClassSearch extends React.Component {
  static contextType = FamilyContext;

  constructor(props) {
    super(props);
    this.state = {
      searchState: urlToSearchState(props.location),
    };
  }

  async componentDidMount() {
    // Algolia defaults to removing facet if results for that facet are 0
    // So on mount, we get all facets to disable it when result is 0
    const index = searchClient.initIndex(AlgoliaIndexes.classes);
    const { facets } = await index.search("", {
      filters: FILTERS,
      facets: ["level", "style", "type"],
    });
    this.setState({
      initialFacets: _.reduce(
        facets,
        (obj, value, key) => ({ ...obj, [key]: Object.keys(value) }),
        {}
      ),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchState } = this.state;
    const { location, refinements, history, preventUrlUpdates } = this.props;

    if (preventUrlUpdates) {
      return;
    }

    if (!_.isEqual(searchState, prevState.searchState)) {
      history.push(searchStateToUrl(this.props, searchState), searchState);
      const filterObj = getFilterEventObj({
        location,
        indexName: AlgoliaIndexes.classes,
        searchState: {
          ...searchState,
          refinementList: { ...searchState.refinementList, ...refinements },
        },
      });
      contentFilterClasses(filterObj);
    }

    // Reset Page when switching Levels, Reset refinementList when switching categories
    if (!_.isEqual(refinements, prevProps.refinements)) {
      if (
        !_.isEqual(refinements?.categories, prevProps.refinements?.categories)
      ) {
        this.setState({
          searchState: {
            ...searchState,
            refinementList: {},
            ...refinements,
            page: 1,
          },
        });
      } else {
        this.setState({
          searchState: { ...searchState, ...refinements, page: 1 },
        });
      }
    }
  }

  setSearchState = ({ newState }) => {
    this.setState({ searchState: newState });
  };

  applyFilters = ({ newState }) => {
    const { history, location } = this.props;
    const { isFamilyModeOn } = this.context;
    const prevPath = location.state?.prevPath;
    const { refinementList, multiRange } = newState;
    const filtersApplied = getFiltersApplied({ refinementList, multiRange });

    const accountMode = isFamilyModeOn
      ? AccountMode.Family
      : AccountMode.Studio;

    if (location.pathname?.includes("/results")) {
      this.setState({ searchState: newState });

      if (prevPath === "/library/categories") {
        libraryFilterContent({
          location: ANALYTICS_LOCATION.library,
          module: LIBRARY_MODULE.library_categories,
          filters_applied: filtersApplied,
          account_mode: accountMode,
        });
      }
    } else if (location.pathname?.includes("/library")) {
      libraryFilterContent({
        location: ANALYTICS_LOCATION.library,
        module: LIBRARY_MODULE.library_classes,
        filters_applied: filtersApplied,
        account_mode: accountMode,
      });
      const pathnameReplace = location.pathname.replace("library", "results");

      history.push(`${pathnameReplace}${createURL(newState)}`, {
        prevPath: location.pathname,
      });
    }
  };

  render() {
    const { initialFacets, indexTitle, searchState } = this.state;
    const {
      refinements,
      classFilters,
      location,
      isFamilyFeatureEnabled,
      hideAvailableToMeFilter,
      hideMobileFilterButton,
      ...restOfPropsForClassList
    } = this.props;

    const searchStateFromURL = urlToSearchState(location);
    if (!_.isEqual(searchStateFromURL.query, searchState.query)) {
      this.setState({ searchState: searchStateFromURL });
    }

    const normalizedRefinements = {
      isFamilyFriendly: [true, false],
      ...refinements,
    };

    return (
      <React.Fragment>
        <FilterModal
          indexName={AlgoliaIndexes.classes}
          indexTitle={indexTitle}
          filterData={classFilters}
          refinements={normalizedRefinements}
          location={location}
          applyFilters={this.applyFilters}
        >
          <Flex flexDirection="column">
            <Div mb={4}>
              <DurationOptions durationItems={DURATION_ITEMS} />
            </Div>
            <LevelOptions mb={4} initialFacets={initialFacets?.level} />

            <TypeOptions
              mb={4}
              initialFacets={initialFacets?.type}
              refinements={normalizedRefinements}
              defaultRefinement={normalizedRefinements.type}
              forceDefault={Boolean(normalizedRefinements.type)}
            />
            <SortOptions
              mb={4}
              defaultRefinement="classes"
              items={SORT_BY_ITEMS}
            />
            <StyleOptions
              mb={4}
              initialFacets={initialFacets?.style}
              refinements={normalizedRefinements}
              defaultRefinement={normalizedRefinements.style}
              forceDefault={Boolean(normalizedRefinements.style)}
            />
            {!hideAvailableToMeFilter && (
              <AvailableToMeToggle
                mb={4}
                defaultRefinement={normalizedRefinements.accessType}
              />
            )}
            {isFamilyFeatureEnabled && (
              <FamilyFriendlyToggle
                mb={4}
                defaultRefinement={normalizedRefinements.isFamilyFriendly}
              />
            )}
            <ExplicitMusicToggle
              mb={4}
              defaultRefinement={normalizedRefinements.explicit}
            />
            <WithMusicOnlyToggle
              mb={4}
              defaultRefinement={normalizedRefinements.isFullyLicensed}
            />
            <InstructorOptions mb={4} />
          </Flex>
          <Configure filters={FILTERS} />
        </FilterModal>

        <ClassResults
          filters={FILTERS}
          refinements={normalizedRefinements}
          searchState={searchState}
          setSearchState={this.setSearchState}
          durationItems={DURATION_ITEMS}
          sortByItems={SORT_BY_ITEMS}
          hideMobileFilterButton={hideMobileFilterButton}
          {...restOfPropsForClassList}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ filters, user }, { refinements }) => {
  const primarySubscription = user.subscription?.primarySubscription;
  const accessTypeParam = refinements?.accessType;
  const userAccessType =
    primarySubscription?.plan?.accessType ?? AccessType.Free;
  const hideAvailableToMeFilter = !!accessTypeParam;
  const availableToMeFilter =
    user.private.show_available_to_me_only === true
      ? UserAccessTypeToAlgoliaFilterMap.get(userAccessType)
      : [];
  const accessType = hideAvailableToMeFilter
    ? accessTypeParam
    : availableToMeFilter;

  return {
    classFilters: filters.classes,
    hideAvailableToMeFilter,
    refinements: {
      ...refinements,
      explicit: user.private.show_explicit ? [true, false] : [false],
      isFullyLicensed:
        user.private.show_only_licensed_classes === false ? [] : [true], // default to true if null
      accessType,
    },
  };
};

ClassSearch.defaultProps = {
  isFamilyFeatureEnabled: false,
  refinements: {},
};

ClassSearch.propTypes = {
  isFamilyFeatureEnabled: PropTypes.bool,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  classFilters: PropTypes.shape({}).isRequired,
  refinements: PropTypes.shape({
    categories: PropTypes.string,
  }),
};

export default withRouter(connect(mapStateToProps, null)(ClassSearch));
